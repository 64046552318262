import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'boxicons';

const UserUpdate = () => {
  const { id } = useParams(); // URL'deki kullanıcı ID'sini al
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Parola göster/gizle kontrolü
  const [loading, setLoading] = useState(true); // Yükleme durumunu takip edin

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await fetch(`https://demo.mashow.xyz/api/users/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Kullanıcı bilgileri getirilemedi');
      }

      const data = await response.json();
      setUsername(data.username);
      setEmail(data.email);
    } catch (error) {
      console.error('Hata:', error);
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Kullanıcı bilgileri yüklenirken bir hata oluştu.',
      });
    } finally {
      setLoading(false); // Yükleme tamamlandı
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`https://demo.mashow.xyz/api/users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ username, email, password }),
      });

      if (!response.ok) {
        throw new Error('Kullanıcı güncellenemedi');
      }

      Swal.fire({
        icon: 'success',
        title: 'Başarılı',
        text: 'Kullanıcı başarıyla güncellendi!',
      });

      navigate('/dashboard/user-list');
    } catch (error) {
      console.error('Hata:', error);
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Kullanıcı güncellenirken bir hata oluştu.',
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (loading) {
    return <p>Yükleniyor...</p>; // Yükleme sırasında gösterilecek mesaj
  }

  return (
    <div>
      <h2 className="text-2xl mb-4">Kullanıcı Güncelle</h2>
      <form onSubmit={handleUpdateUser}>
        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-700 text-sm mb-2">
            Kullanıcı Adı
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 text-sm mb-2">
            Parola (İsteğe bağlı)
          </label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 px-3 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <box-icon name="key" color="gray"></box-icon>
            </button>
          </div>
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Güncelle
        </button>
      </form>
    </div>
  );
};

export default UserUpdate;
