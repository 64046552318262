import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { FaEye, FaEyeSlash,FaUser, FaLock } from 'react-icons/fa';
import axios from 'axios';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://api.ipify.org?format=json')
    .then(response => {
      setIpAddress(response.data.ip); // IP adresini state'e kaydet
    })
    .catch(error => {
      console.error('IP adresi alınamadı:', error);
    });

    const savedUsername = localStorage.getItem('rememberMeUsername');
    const savedPassword = localStorage.getItem('rememberMePassword');

    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true); 
    }
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        navigate('/dashboard');
      } catch (error) {
        console.error("Geçersiz token:", error);
        localStorage.removeItem('authToken');
      }
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (rememberMe) {
      // "Beni Hatırla" işaretliyse kullanıcı adı ve şifreyi kaydet
      localStorage.setItem('rememberMeUsername', username);
      localStorage.setItem('rememberMePassword', password);
    } else {
      // İşaretli değilse önceki kayıtları temizle
      localStorage.removeItem('rememberMeUsername');
      localStorage.removeItem('rememberMePassword');
    }
    
    try {
      const response = await fetch('https://demo.mashow.xyz/api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: username, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        // Spesifik hata mesajını göstermek için kontrol
        let errorMessage = 'Bilinmeyen bir hata oluştu';
        if (data && data.message) {
          errorMessage = data.message;
        }
        Swal.fire({
          icon: 'error',
          title: 'Hata',
          text: errorMessage,
        });
        return;
      }

      const { token } = data;

      Swal.fire({
        icon: 'success',
        title: 'Başarılı',
        text: data.message || 'Giriş başarılı!',
      }).then(() => {
        localStorage.setItem('authToken', token);
        navigate('/dashboard');
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Sunucu hatası. Lütfen daha sonra tekrar deneyin.',
      });
    }
  };

  return (
    <div className="min-h-screen lg:bg-gray-800 flex items-center justify-center">
      <div className="bg-white p-8 rounded-3xl lg:shadow-lg w-full max-w-md">
      <h2 className="text-center text-4xl  mb-2 text-gray-800 font-rubik animate-fade-down">Nil Group</h2>
        <h2 className="text-center text-xl  mb-10 text-gray-800 font-rubik animate-fade-down">RİSK & UYUM SİSTEMİ</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-700 text-base mb-4 animate-fade-down flex items-center">
            <FaUser className="mr-2" />Kullanıcı Adı
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="pl-6 shadow appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline animate-fade-down"
              required
            />
          </div>
          <div className="mb-6 relative animate-fade-down">
      <label htmlFor="password" className="block text-gray-700 text-base mb-4 animate-fade-down flex items-center">
      <FaLock className="mr-2" /> Parola
      </label>
      <input
        type={showPassword ? 'text' : 'password'}
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className=" pl-6 shadow appearance-none border rounded-3xl w-full  py-3 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline "
        required
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-6 bottom-4 transform -translate-y-1/2 text-gray-500 "
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
    <div className="flex items-center mb-4 animate-fade-down">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="rememberMe" className="text-gray-700  mb-1">
              Beni Hatırla
            </label>
          </div>

          <div className="flex items-center justify-center ">
            <button
              type="submit"
              className="bg-yellow-600 hover:bg-yellow-800 text-white font-bold py-3 px-16 rounded-3xl focus:outline-none focus:shadow-outline animate-fade-down"
            >
              Giriş Yap
            </button>
          </div>
        </form>
        <div className="text-center mt-6 text-gray-500">
          IP Adresiniz <b>{ipAddress}</b> <br></br> <b>Nil Group</b> tarafından kayıt altına alınmıştır.
        </div>
      </div>
    </div>
  );
};

export default Login;
