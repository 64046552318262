import React, { useState } from 'react';
import Swal from 'sweetalert2';

const UserAdd = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleAddUser = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://demo.mashow.xyz/api/users/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      });

      if (!response.ok) {
        throw new Error('Kullanıcı eklenemedi');
      }

      Swal.fire({
        icon: 'success',
        title: 'Başarılı',
        text: 'Kullanıcı başarıyla eklendi!',
      });

      setUsername('');
      setEmail('');
      setPassword('');
    } catch (error) {
      console.error('Hata:', error);
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Kullanıcı eklenirken bir hata oluştu.',
      });
    }
  };

  return (
    <div className='lg:p-0 p-8'>
      <h2 className="text-2xl mb-4">Kullanıcı Ekle</h2>
      <form onSubmit={handleAddUser}>
        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-700 text-sm mb-2">
            Kullanıcı Adı
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 text-sm mb-2">
            Şifre
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Ekle
        </button>
      </form>
    </div>
  );
};

export default UserAdd;
