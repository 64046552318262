import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/tr'; // Türkçe tarih formatı

const RemovedCompanyDetails = () => {
  const { id } = useParams();
  const [companyData, setCompanyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Filtrelenmiş veriler için state
  const [searchText, setSearchText] = useState(''); // Arama kutusu için state

  useEffect(() => {
    const fetchRemovedCompanyData = async () => {
      try {
        // Kaldırılan şirket verileri için yeni API endpoint'i
        const response = await axios.get(`https://demo.mashow.xyz/api/companies/company/${id}/removed`);
        const company = response.data;

        // Tüm veriyi topluyoruz ve ID ekliyoruz
        let allData = [
          ...company.realPersons.map((person, index) => ({
            id: index + 1,  // ID ekliyoruz
            ...person,
            companyType: "Şahıs Şirketi",
            companyName: person.name + ' ' + person.surname,
            signCircular: "VAR",
            customerInfoForm: "YOK",
            tradeRegistryGazette: "YOK",
            activityCertificate: "YOK",
            activityDate: person.taxRegistrationDate
              ? moment(person.taxRegistrationDate).format('DD.MM.YYYY')
              : 'Bilgi yok',
            taxNumber: person.TCKN,
            taxOffice: "YOK",
            goldsmithCert: "YOK",
            contact: person.address || 'Bilgi yok',
            note: 'Bilgi yok'
          })),
          ...company.legalPersons.map((person, index) => ({
            id: index + 1 + company.realPersons.length,  // ID sıralaması devam eder
            ...person,
            companyType: "Limited Şirketi",
            companyName: person.title,
            signCircular: person.signatureCircular ? "VAR" : "YOK",
            customerInfoForm: "YOK",
            tradeRegistryGazette: person.tradeRegistryNumber ? "VAR" : "YOK",
            activityCertificate: "VAR",
            activityDate: "YOK",
            taxNumber: person.taxNumber,
            taxOffice: person.taxOffice || "Bilgi yok",
            goldsmithCert: person.kepAddress || "YOK",
            contact: person.phone || 'Bilgi yok',
            note: 'Bilgi yok'
          })),
          ...company.representatives.map((rep, index) => ({
            id: index + 1 + company.realPersons.length + company.legalPersons.length,  // ID sıralaması devam eder
            ...rep,
            companyType: "Temsilci",
            companyName: rep.name + ' ' + rep.surname,
            signCircular: "VAR",
            customerInfoForm: "YOK",
            tradeRegistryGazette: "YOK",
            activityCertificate: "YOK",
            activityDate: rep.birthDate
              ? moment(rep.birthDate).format('DD.MM.YYYY')
              : 'Bilgi yok',
            taxNumber: rep.TCKN,
            taxOffice: "YOK",
            goldsmithCert: "YOK",
            contact: rep.address || 'Bilgi yok',
            note: 'Bilgi yok'
          }))
        ];

        setCompanyData(allData);
        setFilteredData(allData); // İlk başta tüm veriyi filtrelenmiş veriye ekliyoruz
      } catch (error) {
        console.error('Veriler çekilemedi:', error);
        Swal.fire({
          icon: 'error',
          title: 'Hata',
          text: 'Kaldırılan şirket verileri alınamadı!',
        });
      }
    };

    fetchRemovedCompanyData();
  }, [id]);

  // Arama kutusundaki değişiklikleri dinler ve tabloyu filtreler
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filtered = companyData.filter(item =>
      item.companyName.toLowerCase().includes(value) || // Şirket Adına göre filtreleme
      item.companyType.toLowerCase().includes(value) || // Firma Ünvanına göre filtreleme
      item.taxNumber.toLowerCase().includes(value)      // Vergi Numarasına göre filtreleme
    );

    setFilteredData(filtered);
  };

  // Tablo için sütunlar
  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id, // ID sütunu
      sortable: true,
      width: '50px', // ID sütunu dar olsun
    },
    {
      name: 'FİRMA ÜNVANI',
      selector: (row) => row.companyType || 'Bilgi yok',
      sortable: true,
    },
    {
      name: 'ŞİRKET ADI',
      selector: (row) => row.companyName || 'Bilgi yok',
      sortable: true,
      wrap: true, // Yazıyı bir satıra sığdırmak için
    },
    {
      name: 'İMZA SİRKÜLE',
      selector: (row) => row.signCircular || 'Bilgi yok',
      sortable: true,
    },
  ];

  // Genişletilebilir satırların içeriği
  const expandableRowsComponent = ({ data }) => (
    <div className='p-4'>
      <table>
        <tbody>
          <tr>
            <td><strong>MÜŞTERİ BİLGİ FORMU:</strong></td>
            <td>{data.customerInfoForm || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>TİCARET SİCİL GAZETESİ:</strong></td>
            <td>{data.tradeRegistryGazette || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>FAALİYET BELGESİ:</strong></td>
            <td>{data.activityCertificate || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>FAALİYET BELGE TARİHİ:</strong></td>
            <td>{data.activityDate || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>VERGİ NUMARASI:</strong></td>
            <td>{data.taxNumber || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>VERGİ DAİRESİ:</strong></td>
            <td>{data.taxOffice || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>KUYUMCU YETKİ BELGESİ:</strong></td>
            <td>{data.goldsmithCert || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>İLETİŞİM:</strong></td>
            <td>{data.contact || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>NOT:</strong></td>
            <td>{data.note || 'Bilgi yok'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Kaldırılan Veriler</h2>

      {/* Arama kutusu */}
      <input
        type="text"
        placeholder="Ara..."
        className="mb-4 p-2 border border-gray-300 rounded w-full"
        value={searchText}
        onChange={handleSearch} // Arama kutusu için event listener
      />

      <DataTable
        columns={columns}
        data={filteredData} // Filtrelenmiş veriyi tabloya ekliyoruz
        expandableRows
        expandableRowsComponent={expandableRowsComponent} // Genişletilebilir satırları ekliyoruz
        pagination
        highlightOnHover
        striped
        noHeader
        responsive={true} // Tabloyu duyarlı hale getirir
        noDataComponent="Gösterilecek veri yok"
        paginationComponentOptions={{
          rowsPerPageText: 'Sayfa başına satır:',
          rangeSeparatorText: 'ile',
          selectAllRowsItem: true,
          selectAllRowsItemText: 'Tümünü Seç',
        }}
      />
    </div>
  );
};

export default RemovedCompanyDetails;
