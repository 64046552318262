import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';

const RemovedCompaniesList = () => {
  const [removedCompanies, setRemovedCompanies] = useState([]);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    fetchRemovedCompanies();
  }, []);

  // Kaldırılan şirketleri getirme
  const fetchRemovedCompanies = async () => {
    try {
      const response = await axios.get('https://demo.mashow.xyz/api/companies/removed');
      setRemovedCompanies(response.data);
    } catch (error) {
      console.error('Kaldırılan şirketler alınamadı:', error);
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Kaldırılan şirketler yüklenemedi.',
      });
    }
  };

  // Şirketi geri getirme işlevi
  const handleRestore = async (companyId) => {
    Swal.fire({
      title: 'Emin misiniz?',
      text: 'Bu şirketi geri getirmek üzeresiniz!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet, geri getir!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.put(`https://demo.mashow.xyz/api/companies/${companyId}/restore`);
          Swal.fire('Geri Getirildi!', 'Şirket başarıyla geri getirildi.', 'success');

          // Geri getirilen şirketi state'ten çıkar
          setRemovedCompanies((prevCompanies) =>
            prevCompanies.filter((company) => company._id !== companyId)
          );
        } catch (error) {
          console.error('Şirket geri getirilemedi:', error);
          Swal.fire({
            icon: 'error',
            title: 'Hata',
            text: 'Şirket geri getirilemedi.',
          });
        }
      }
    });
  };

  // DataTable sütunları
  const columns = [
    {
      name: 'Sıra',
      selector: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: 'Şirket Adı',
      selector: (row) => row.name || 'Ad bilgisi yok',
      sortable: true,
    },
    {
      name: 'Şirket Bilgileri',
      selector: (row) => row.info || 'Bilgi yok',
      sortable: true,
    },
    {
      name: 'İşlemler',
      cell: (row) => (
        <div className="flex space-x-2">
          <button
            onClick={() => handleRestore(row._id)}
            className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
          >
            <box-icon name="undo" color="white"></box-icon>
            <span className="ml-2">Geri Getir</span>
          </button>
        </div>
      ),
    },
  ];

  // Arama için filtreleme
  const filteredRemovedCompanies = removedCompanies.filter((company) =>
    company.name && company.name.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Kaldırılan Şirketler</h2>

      {/* Arama Alanı */}
      <input
        type="text"
        className="mb-4 p-2 border border-gray-300 rounded w-full"
        placeholder="Şirket Ara"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />

      <DataTable
        columns={columns}
        data={filteredRemovedCompanies} // Filtrelenmiş veriyi tabloya gönderiyoruz
        pagination
        highlightOnHover
        striped
        noHeader
        noDataComponent="Gösterilecek kayıt yok"
        paginationComponentOptions={{
          rowsPerPageText: 'Sayfa başına satır:',
          rangeSeparatorText: 'ile',
          selectAllRowsItem: true,
          selectAllRowsItemText: 'Tümünü Seç',
        }}
      />
    </div>
  );
};

export default RemovedCompaniesList;
