import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'boxicons';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users.filter(user =>
        user.username.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, users]);

  // Kullanıcıları backend'den çek
  const fetchUsers = async () => {
    try {
      const response = await fetch('https://demo.mashow.xyz/api/users', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Kullanıcılar getirilemedi');
      }
  
      const data = await response.json();
      console.log(data); // Burada API'den gelen veriyi konsola yazdırıyoruz

      // Admin kullanıcıları filtrele, admin kullanıcı görünmeyecek
      const nonAdminUsers = data.filter(user => user.username !== 'admin'); // Admin kullanıcıyı filtreliyoruz
      setUsers(nonAdminUsers);
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  // Kullanıcıyı sil
  const handleDeleteUser = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Emin misiniz?',
        text: 'Bu kullanıcıyı silmek istediğinize emin misiniz?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, sil!',
        cancelButtonText: 'İptal',
      });

      if (result.isConfirmed) {
        const response = await fetch(`https://demo.mashow.xyz/api/users/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Kullanıcı silinemedi');
        }

        setUsers(users.filter(user => user._id !== id)); // Kullanıcıyı listeden çıkar
        await Swal.fire('Silindi!', 'Kullanıcı başarıyla silindi.', 'success');
      }
    } catch (error) {
      console.error('Hata:', error);
      await Swal.fire('Hata!', 'Kullanıcı silinirken bir hata oluştu.', 'error');
    }
  };

  // Kullanıcıyı güncelleme sayfasına yönlendir
  const handleUpdateUser = (id) => {
    navigate(`/dashboard/user-update/${id}`);
  };

  const columns = [
    {
      name: 'Sıra',
      selector: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: 'Kullanıcı Adı',
      selector: row => row.username,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'İşlemler',
      cell: row => (
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
        <button
          onClick={() => handleUpdateUser(row._id)}
          className="bg-yellow-500 hover:bg-yellow-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
        >
          <box-icon name="edit" color="white"></box-icon>
          <span className="ml-2">Güncelle</span>
        </button>
        <button
          onClick={() => handleDeleteUser(row._id)}
          className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
        >
          <box-icon name="trash" color="white"></box-icon>
          <span className="ml-2">Sil</span>
        </button>
      </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
  };

  return (
    <div className="w-full max-w-full mx-auto">
      <h2 className="text-2xl mb-4">Kullanıcı Listesi</h2>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Arama..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="overflow-x-auto">
        <DataTable
          columns={columns}
          data={filteredUsers} // Filtrelenmiş kullanıcı verisi
          pagination
          highlightOnHover
          striped
          customStyles={customStyles}
          noDataComponent="Veri Bulunamadı"
          paginationComponentOptions={{
            rowsPerPageText: 'Sayfa başına satır:',
            rangeSeparatorText: ' / ',
          }}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default UserList;
