import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const CompanyLogin = () => {
  const [formData, setFormData] = useState({
    companyId: '',  // Şirket ID'si burada tutulacak
    info: '',
    realPersons: [],
    legalPersons: [],
    representatives: [],
  });
  const [companies, setCompanies] = useState([]); // Şirketlerin listesi

  // Şirketleri sunucudan almak için useEffect kullanıyoruz
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('https://demo.mashow.xyz/api/companies/active2'); // Şirketleri sunucudan al
        setCompanies(response.data);
      } catch (error) {
        console.error('Şirketler alınırken hata oluştu:', error);
      }
    };
    fetchCompanies();
  }, []);

  const handleInputChange = (e, index, fieldName, type) => {
    const updatedList = [...formData[type]];
    updatedList[index][fieldName] = e.target.value;
    setFormData({ ...formData, [type]: updatedList });
  };

  const handleAddRealPerson = () => {
    setFormData({
      ...formData,
      realPersons: [...formData.realPersons, { TCKN: '', name: '', surname: '', motherName: '', fatherName: '', birthPlace: '', birthDate: '', nationality: '', identityDocumentType: '', identityDocumentNumber: '', address: '' }],
    });
  };

  const handleAddLegalPerson = () => {
    setFormData({
      ...formData,
      legalPersons: [...formData.legalPersons, { title: '', tradeRegistryOffice: '', tradeRegistryNumber: '', MERSIS: '', taxNumber: '', taxOffice: '', activityArea: '', address: '', phone: '', fax: '', email: '', kepAddress: '' }],
    });
  };

  const handleAddRepresentative = () => {
    setFormData({
      ...formData,
      representatives: [...formData.representatives, { TCKN: '', name: '', surname: '', motherName: '', fatherName: '', birthPlace: '', birthDate: '', nationality: '', identityDocumentType: '', identityDocumentNumber: '', address: '' }],
    });
  };

  const handleRemove = (index, type) => {
    const updatedList = formData[type].filter((_, i) => i !== index);
    setFormData({ ...formData, [type]: updatedList });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://demo.mashow.xyz/api/companydata', formData);
      Swal.fire('Başarılı!', 'Veriler başarıyla eklendi.', 'success');
    } catch (error) {
      Swal.fire('Hata!', 'Veri eklenirken hata oluştu.', 'error');
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Veri Girişi Yap</h2>

      <form onSubmit={handleSubmit}>
        {/* Şirket Adı ve Bilgileri */}
        <div className="mb-4">
          <label>Şirket Seçin:</label>
          <select
            name="companyId"
            value={formData.companyId}
            onChange={(e) => setFormData({ ...formData, companyId: e.target.value })}
            className="border p-2 rounded w-full mb-2"
          >
            <option value="">Şirket Seçin</option>
            {companies.map((company) => (
              <option key={company._id} value={company._id}>
                {company.name}
              </option>
            ))}
          </select>

        </div>

        {/* Gerçek Kişi Bilgileri */}
        <h3 className="text-xl mb-2">Gerçek Kişi Bilgileri</h3>
        {formData.realPersons.map((person, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-50 rounded">
            <label>TCKN:</label>
            <input type="text" name="TCKN" value={person.TCKN} onChange={(e) => handleInputChange(e, index, 'TCKN', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Ad:</label>
            <input type="text" name="name" value={person.name} onChange={(e) => handleInputChange(e, index, 'name', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Soyad:</label>
            <input type="text" name="surname" value={person.surname} onChange={(e) => handleInputChange(e, index, 'surname', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Anne Adı:</label>
            <input type="text" name="motherName" value={person.motherName} onChange={(e) => handleInputChange(e, index, 'motherName', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Baba Adı:</label>
            <input type="text" name="fatherName" value={person.fatherName} onChange={(e) => handleInputChange(e, index, 'fatherName', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Doğum Yeri:</label>
            <input type="text" name="birthPlace" value={person.birthPlace} onChange={(e) => handleInputChange(e, index, 'birthPlace', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Doğum Tarihi:</label>
            <input type="date" name="birthDate" value={person.birthDate} onChange={(e) => handleInputChange(e, index, 'birthDate', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Uyruğu:</label>
            <input type="text" name="nationality" value={person.nationality} onChange={(e) => handleInputChange(e, index, 'nationality', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Kimlik Belgesi Türü:</label>
            <input type="text" name="identityDocumentType" value={person.identityDocumentType} onChange={(e) => handleInputChange(e, index, 'identityDocumentType', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Kimlik Belgesi Numarası:</label>
            <input type="text" name="identityDocumentNumber" value={person.identityDocumentNumber} onChange={(e) => handleInputChange(e, index, 'identityDocumentNumber', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Adres:</label>
            <input type="text" name="address" value={person.address} onChange={(e) => handleInputChange(e, index, 'address', 'realPersons')} className="border p-2 rounded w-full mb-2" />
            <button type="button" onClick={() => handleRemove(index, 'realPersons')} className="bg-red-500 text-white p-2 rounded">Kişiyi Kaldır</button>
          </div>
        ))}
        <button type="button" onClick={handleAddRealPerson} className="bg-blue-500 text-white p-4 rounded mb-4">Gerçek Kişi Ekle</button>

        {/* Tüzel Kişi Bilgileri */}
        <h3 className="text-xl mb-2">Tüzel Kişi Bilgileri</h3>
        {formData.legalPersons.map((person, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-50 rounded">
            <label>Unvan:</label>
            <input type="text" name="title" value={person.title} onChange={(e) => handleInputChange(e, index, 'title', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Ticaret Sicil Dairesi:</label>
            <input type="text" name="tradeRegistryOffice" value={person.tradeRegistryOffice} onChange={(e) => handleInputChange(e, index, 'tradeRegistryOffice', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Ticaret Sicil Numarası:</label>
            <input type="text" name="tradeRegistryNumber" value={person.tradeRegistryNumber} onChange={(e) => handleInputChange(e, index, 'tradeRegistryNumber', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>MERSIS Numarası:</label>
            <input type="text" name="MERSIS" value={person.MERSIS} onChange={(e) => handleInputChange(e, index, 'MERSIS', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Vergi Numarası:</label>
            <input type="text" name="taxNumber" value={person.taxNumber} onChange={(e) => handleInputChange(e, index, 'taxNumber', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Vergi Dairesi:</label>
            <input type="text" name="taxOffice" value={person.taxOffice} onChange={(e) => handleInputChange(e, index, 'taxOffice', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Faaliyet Alanı:</label>
            <input type="text" name="activityArea" value={person.activityArea} onChange={(e) => handleInputChange(e, index, 'activityArea', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Adres:</label>
            <input type="text" name="address" value={person.address} onChange={(e) => handleInputChange(e, index, 'address', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Telefon:</label>
            <input type="text" name="phone" value={person.phone} onChange={(e) => handleInputChange(e, index, 'phone', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>Faks:</label>
            <input type="text" name="fax" value={person.fax} onChange={(e) => handleInputChange(e, index, 'fax', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>E-posta:</label>
            <input type="email" name="email" value={person.email} onChange={(e) => handleInputChange(e, index, 'email', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <label>KEP Adresi:</label>
            <input type="text" name="kepAddress" value={person.kepAddress} onChange={(e) => handleInputChange(e, index, 'kepAddress', 'legalPersons')} className="border p-2 rounded w-full mb-2" />
            <button type="button" onClick={() => handleRemove(index, 'legalPersons')} className="bg-red-500 text-white p-2 rounded">Tüzel Kişiyi Kaldır</button>
          </div>
        ))}
        <button type="button" onClick={handleAddLegalPerson} className="bg-blue-500 text-white p-4 rounded mb-4">Tüzel Kişi Ekle</button>

        {/* Temsilci Bilgileri */}
        <h3 className="text-xl mb-2">Temsilci Bilgileri</h3>
        {formData.representatives.map((rep, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-50 rounded">
            <label>TCKN:</label>
            <input type="text" name="TCKN" value={rep.TCKN} onChange={(e) => handleInputChange(e, index, 'TCKN', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Ad:</label>
            <input type="text" name="name" value={rep.name} onChange={(e) => handleInputChange(e, index, 'name', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Soyad:</label>
            <input type="text" name="surname" value={rep.surname} onChange={(e) => handleInputChange(e, index, 'surname', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Anne Adı:</label>
            <input type="text" name="motherName" value={rep.motherName} onChange={(e) => handleInputChange(e, index, 'motherName', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Baba Adı:</label>
            <input type="text" name="fatherName" value={rep.fatherName} onChange={(e) => handleInputChange(e, index, 'fatherName', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Doğum Yeri:</label>
            <input type="text" name="birthPlace" value={rep.birthPlace} onChange={(e) => handleInputChange(e, index, 'birthPlace', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Doğum Tarihi:</label>
            <input type="date" name="birthDate" value={rep.birthDate} onChange={(e) => handleInputChange(e, index, 'birthDate', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Uyruğu:</label>
            <input type="text" name="nationality" value={rep.nationality} onChange={(e) => handleInputChange(e, index, 'nationality', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Kimlik Belgesi Türü:</label>
            <input type="text" name="identityDocumentType" value={rep.identityDocumentType} onChange={(e) => handleInputChange(e, index, 'identityDocumentType', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Kimlik Belgesi Numarası:</label>
            <input type="text" name="identityDocumentNumber" value={rep.identityDocumentNumber} onChange={(e) => handleInputChange(e, index, 'identityDocumentNumber', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <label>Adres:</label>
            <input type="text" name="address" value={rep.address} onChange={(e) => handleInputChange(e, index, 'address', 'representatives')} className="border p-2 rounded w-full mb-2" />
            <button type="button" onClick={() => handleRemove(index, 'representatives')} className="bg-red-500 text-white p-2 rounded">Temsilciyi Kaldır</button>
          </div>
        ))}
        <button type="button" onClick={handleAddRepresentative} className="bg-blue-500 text-white p-4 rounded mb-4">Temsilci Ekle</button>
        <br></br>
        <button type="submit" className="bg-green-500 text-white p-4 rounded mb-4">Verileri Gönder</button>
      </form>
    </div>
  );
};

export default CompanyLogin;
