import React, { useState, useEffect } from 'react';
import { useNavigate, Link, Routes, Route } from 'react-router-dom';
import 'boxicons';
import { FaHome, FaUsers, FaCog, FaBuilding, FaCircle, FaDatabase } from 'react-icons/fa'; // react-icons'dan gerekli ikonları ekleyin
import UserAdd from './UserAdd';
import UserList from './UserList';
import UserUpdate from './UserUpdate';
import CompanyAdd from './CompanyAdd';
import CompanyList from './CompanyList';
import RemovedCompaniesList from './RemovedCompaniesList'; // Kaldırılanlar bileşenini ekledik
import CompanyDetails from './CompanyDetails';
import RemovedCompanyDetails from './RemovedCompanyDetails';
import CompanyLogin from './CompanyLogin';
import UpdateData from './TempUpdateData';

import axios from 'axios';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUserSubMenuOpen, setIsUserSubMenuOpen] = useState(false);
  const [companies, setCompanies] = useState([]); // Şirketleri tutmak için state
  const [isCompanySubMenuOpen, setIsCompanySubMenuOpen] = useState(false);
  const [isCompany2SubMenuOpen, setIsCompany2SubMenuOpen] = useState({}); // Şirketlerin alt menülerini kontrol etmek için nesne
  const navigate = useNavigate();

  // Şirket verilerini almak için useEffect kullanıyoruz
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('https://demo.mashow.xyz/api/companies/active2'); // removed: false olanları getir
        setCompanies(response.data);
      } catch (error) {
        console.error('Şirket verileri alınamadı:', error);
      }
    };
  
    fetchCompanies();
  
    // Event dinleyici oluştur
    const eventSource = new EventSource('https://demo.mashow.xyz/api/companies/active');
  
    eventSource.onmessage = (event) => {
      const change = JSON.parse(event.data);
      const operationType = change.operationType;
  
      if (operationType === "insert") {
        const newCompanyData = change.fullDocument;
        if (newCompanyData && newCompanyData.removed === false) {
          setCompanies((prevCompanies) => [...prevCompanies, newCompanyData]);
        }
      } else if (operationType === "update") {
        const updatedCompanyData = change.fullDocument;
        if (updatedCompanyData && updatedCompanyData._id) {
          if (updatedCompanyData.removed === false) {
            // Şirket geri getirildiğinde listeye ekle veya güncelle
            setCompanies((prevCompanies) =>
              prevCompanies.map((company) =>
                company._id === updatedCompanyData._id ? updatedCompanyData : company
              )
            );
          } else {
            // removed === true ise şirketi state'den çıkar
            setCompanies((prevCompanies) =>
              prevCompanies.filter((company) => company._id !== updatedCompanyData._id)
            );
          }
        }
      }
    };
  
    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSource.close();
    };
  
    return () => {
      eventSource.close();
    };
  }, []);
  
  

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleUserSubMenu = () => {
    setIsUserSubMenuOpen(!isUserSubMenuOpen);
  };

  const toggleCompanySubMenu = () => {
    setIsCompanySubMenuOpen(!isCompanySubMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // authToken'ı temizleyerek çıkış yap
    navigate('/login'); // Login sayfasına yönlendir
  };

  // Her şirket için alt menüyü açıp kapatmaya yarayan fonksiyon
  const toggleCompany2SubMenu = (companyId) => {
    setIsCompany2SubMenuOpen((prevState) => ({
      ...prevState,
      [companyId]: !prevState[companyId] // Her şirket için durumu tersine çevir
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Header */}
      <header className="bg-gray-900 text-white p-4 flex justify-between items-center">
        <button onClick={toggleSidebar} className="text-3xl md:hidden">
          <box-icon name="menu" color="white"></box-icon>
        </button>
        <h1 className="text-xl font-bold">Risk & Uyum Yönetimi</h1>

        <button onClick={handleLogout} className="text-3xl">
          <box-icon name="log-out-circle" color="white"></box-icon>
        </button>
      </header>

      <div className="flex flex-1">
        {/* Sidebar */}
        <div
          className={`bg-gray-800 text-white p-6 fixed inset-0 z-50 md:static md:w-64 md:block ${
            isSidebarOpen ? 'block' : 'hidden'
          }`}
        >
          {/* Sidebar Header with Close Button */}
          <div className="flex justify-between items-center mb-6 md:hidden">
            <h1 className="text-xl font-bold">Admin Panel</h1>
            <button onClick={toggleSidebar} className="text-4xl">
              <box-icon name="x" color="white"></box-icon>
            </button>
          </div>

          <ul>
            <li className="mb-4 border-b border-gray-700 pb-2 flex items-center">
              <FaHome className="mr-2" /> {/* Home ikonu */}
              <Link to="/dashboard" className="text-gray-300 hover:text-white" onClick={() => setIsSidebarOpen(false)}>
                Anasayfa
              </Link>
            </li>

            {/* Kullanıcılar Menüsü */}
            <li className="mb-4 border-b border-gray-700 pb-2">
              <div onClick={toggleUserSubMenu} className="text-gray-300 hover:text-white cursor-pointer flex items-center justify-between">
                <span className="flex items-center">
                  <FaUsers className="mr-2 text-lg" /> {/* Users ikonu */}
                  Kullanıcılar
                </span>
                <box-icon name={isUserSubMenuOpen ? "chevron-up" : "chevron-down"} color="white"></box-icon>
              </div>
              {isUserSubMenuOpen && (
                <ul className="pl-4 mt-4">
                  <li className="mb-4 flex items-center">
                    <FaCircle className="mr-2 text-xs" /> {/* Users ekleme ikonu */}
                    <Link to="/dashboard/user-add" className="text-gray-300 hover:text-white" onClick={() => setIsSidebarOpen(false)}>
                      Kullanıcı Ekle
                    </Link>
                  </li>
                  <li className="mb-4 flex items-center">
                    <FaCircle className="mr-2 text-xs" /> {/* Users listesi ikonu */}
                    <Link to="/dashboard/user-list" className="text-gray-300 hover:text-white" onClick={() => setIsSidebarOpen(false)}>
                      Kullanıcı Listesi
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* Şirketler Menüsü */}
            <li className="mb-4 border-b border-gray-700 pb-2">
              <div onClick={toggleCompanySubMenu} className="text-gray-300 hover:text-white cursor-pointer flex items-center justify-between">
                <span className="flex items-center">
                  <FaBuilding className="mr-2 text-base" /> {/* Şirketler ikonu */}
                  Şirketler
                </span>
                <box-icon name={isCompanySubMenuOpen ? "chevron-up" : "chevron-down"} color="white"></box-icon>
              </div>
              {isCompanySubMenuOpen && (
                <ul className="pl-4 mt-4">
                  <li className="mb-4 flex items-center">
                  <FaCircle className="mr-2 text-xs" /> 
                    <Link to="/dashboard/company-add" className="text-gray-300 hover:text-white" onClick={() => setIsSidebarOpen(false)}>
                    Şirket Ekle
                    </Link>
                  </li>
                  <li className="mb-4 flex items-center">
                  <FaCircle className="flex mr-2 text-xs" /> 
                    <Link to="/dashboard/company-list" className="text-gray-300 hover:text-white" onClick={() => setIsSidebarOpen(false)}>
                    Tüm Şirketler
                    </Link>
                  </li>
                  {/* Kaldırılanlar Menüsü */}
                  <li className="mb-4 flex items-center">
                  <FaCircle className="flex mr-2 text-xs" /> 
                    <Link to="/dashboard/removed-companies" className="text-gray-300 hover:text-white" onClick={() => setIsSidebarOpen(false)}>
                    Kaldırılanlar
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {companies.filter(company => company.removed === false).map((company) => (
              <li key={company._id} className="mb-4 border-b border-gray-700 pb-2">
                <div
                  onClick={() => toggleCompany2SubMenu(company._id)}
                  className="text-gray-300 hover:text-white cursor-pointer flex items-center justify-between"
                >
                  <span className="flex items-center">
                    <FaDatabase className="mr-2 text-base" /> {/* Şirket ikonu */}
                    {company.name}
                  </span>
                  <box-icon
                    name={isCompany2SubMenuOpen[company._id] ? 'chevron-up' : 'chevron-down'}
                    color="white"
                  ></box-icon>
                </div>
                {isCompany2SubMenuOpen[company._id] && (
                  <ul className="pl-4 mt-4">
                    <li className="mb-4 flex items-center">
                      <FaCircle className="mr-2 text-xs" /> {/* Firma Bilgileri */}
                      <Link to={`/dashboard/company/${company._id}/info`} className="text-gray-300 hover:text-white">
                        Firma Bilgileri
                      </Link>
                    </li>
                    <li className="mb-4 flex items-center">
                      <FaCircle className="mr-2 text-xs" /> {/* Giriş Yap */}
                      <Link to={`/dashboard/company/${company._id}/login`} className="text-gray-300 hover:text-white">
                        Giriş Yap
                      </Link>
                    </li>
                    <li className="mb-4 flex items-center">
                      <FaCircle className="mr-2 text-xs" /> {/* Kaldırılanlar */}
                      <Link to={`/dashboard/company/${company._id}/removed`} className="text-gray-300 hover:text-white">
                        Kaldırılanlar
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            ))}
            <li className="mb-4 border-b border-gray-700 pb-2 flex items-center">
              <FaCog className="mr-2" /> {/* Ayarlar ikonu */}
              <Link to="#" className="text-gray-300 hover:text-white">
                Ayarlar
              </Link>
            </li>
          </ul>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-4 md:p-6 lg:p-8 max-w-full mx-auto">
          <Routes>
            <Route path="/" element={<h1 className="text-2xl md:text-4xl font-bold mb-4 md:mb-6 text-gray-800">Anasayfa</h1>} />
            <Route path="user-add" element={<UserAdd />} />
            <Route path="user-list" element={<UserList />} />
            <Route path="user-update/:id" element={<UserUpdate />} />

            {/* Şirket Rotaları */}
            <Route path="company-add" element={<CompanyAdd />} />
            <Route path="company-list" element={<CompanyList />} />
            <Route path="removed-companies" element={<RemovedCompaniesList />} /> {/* Kaldırılanlar bileşeni */}
            <Route path="company/:id/info" element={<CompanyDetails />} />
            <Route path="company/:id/login" element={<CompanyLogin />} />
            <Route path="company/:id/removed" element={<RemovedCompanyDetails />} />
            <Route path="company/:id/update/:updateId" element={<UpdateData />} />


          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
