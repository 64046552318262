import React from 'react';
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';


const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    Swal.fire({
      icon: 'warning',
      title: 'Yetkisiz Erişim',
      text: 'Lütfen giriş yapın',
      confirmButtonText: 'Tamam'
    });

    return <Navigate to="/login" />;
  }

  try {
    const decodedToken = jwtDecode(token);
    console.log("Decoded Token:", decodedToken);
    // Burada token doğrulama veya expiration kontrolü yapılabilir
  } catch (e) {
    console.error("Token decoding failed:", e);
    localStorage.removeItem('authToken');
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
