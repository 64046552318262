import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const UpdateData = () => {
  const { id, updateId } = useParams(); // Parametre olarak gelen id
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null); // Başlangıçta boş
  const [type, setType] = useState(''); // Türü tutacak state (Gerçek Kişi, Tüzel Kişi, Temsilci)
  const [companyDocuments, setCompanyDocuments] = useState({
    vergiLevhası: null,
    faaliyetBelgesi: null,
    imzaSirküleri: null,
    yetkiliKimlikFotokopisi: null,
    ticaretSicilGazetesi: null,
    kuyumcuYetkiBelgesi: null,
    cerceveSozlesmesi: null
  });
  const [description, setDescription] = useState(''); // Cari için açıklama veya not

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://demo.mashow.xyz/api/companies/company/${id}/data/${updateId}`);
        const data = response.data;

        // Veri türünü tespit et (Gerçek Kişi, Tüzel Kişi, Temsilci)
        if (data.TCKN) {
          setType('realPerson');
        } else if (data.title) {
          setType('legalPerson');
        } else if (data.representationAuth) {
          setType('representative');
        }

        setFormData(data);
      } catch (error) {
        console.error('Veri alınamadı:', error);
        Swal.fire({
          icon: 'error',
          title: 'Hata',
          text: 'Veri alınamadı!',
        });
      }
    };

    fetchData();
  }, [id]);

  // Formdaki input değişikliklerini yönetir
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Dosya ekleme işlemi
  const handleFileChange = (e, documentType) => {
    setCompanyDocuments({ ...companyDocuments, [documentType]: e.target.files[0] });
  };

  // Cari açıklama veya not
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // Formu gönderme işlemi
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formToSend.append(key, formData[key]);
    });

    // Şirket evraklarını ekle
    Object.keys(companyDocuments).forEach((doc) => {
      if (companyDocuments[doc]) {
        formToSend.append(doc, companyDocuments[doc]);
      }
    });

    // Cari açıklama veya not
    formToSend.append('description', description);

    try {
      await axios.put(`https://demo.mashow.xyz/api/companydata/${id}`, formToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      Swal.fire('Başarılı!', 'Veri başarıyla güncellendi.', 'success');
      navigate('/'); // Ana sayfaya yönlendir
    } catch (error) {
      Swal.fire('Hata!', 'Veri güncellenirken hata oluştu.', 'error');
    }
  };

  if (!formData) return <div>Yükleniyor...</div>; // Veri yüklenirken gösterilen mesaj

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Veri Güncelleme</h2>

      <form onSubmit={handleSubmit}>
        {/* Gerçek Kişi Bilgileri */}
        {type === 'realPerson' && (
          <div>
            <label>TCKN:</label>
            <input
              type="text"
              name="TCKN"
              value={formData.TCKN || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
              readOnly
            />
            <label>Ad:</label>
            <input
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
            />
            <label>Soyad:</label>
            <input
              type="text"
              name="surname"
              value={formData.surname || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
            />
            <label>Doğum Tarihi:</label>
            <input
              type="date"
              name="birthDate"
              value={formData.birthDate || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
            />
          </div>
        )}

        {/* Tüzel Kişi Bilgileri */}
        {type === 'legalPerson' && (
          <div>
            <label>Unvan:</label>
            <input
              type="text"
              name="title"
              value={formData.title || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
              readOnly
            />
            <label>Vergi Numarası:</label>
            <input
              type="text"
              name="taxNumber"
              value={formData.taxNumber || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
            />
            <label>Faaliyet Alanı:</label>
            <input
              type="text"
              name="activityArea"
              value={formData.activityArea || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
            />
          </div>
        )}

        {/* Temsilci Bilgileri */}
        {type === 'representative' && (
          <div>
            <label>Ad:</label>
            <input
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
            />
            <label>Soyad:</label>
            <input
              type="text"
              name="surname"
              value={formData.surname || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
            />
            <label>TCKN:</label>
            <input
              type="text"
              name="TCKN"
              value={formData.TCKN || ''}
              onChange={handleInputChange}
              className="border p-2 rounded w-full mb-2"
            />
          </div>
        )}

        {/* Şirket evrakları ekleme alanı */}
        <div className="mb-4">
          <label>Vergi Levhası:</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'vergiLevhası')} className="border p-2 rounded w-full mb-2" />
          {formData.vergiLevhası && <p>Yüklü Dosya: <a href={formData.vergiLevhası} target="_blank" rel="noopener noreferrer">Dosyayı Görüntüle</a></p>}
          
          <label>Faaliyet Belgesi:</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'faaliyetBelgesi')} className="border p-2 rounded w-full mb-2" />
          {formData.faaliyetBelgesi && <p>Yüklü Dosya: <a href={formData.faaliyetBelgesi} target="_blank" rel="noopener noreferrer">Dosyayı Görüntüle</a></p>}
          
          <label>İmza Sirküleri:</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'imzaSirküleri')} className="border p-2 rounded w-full mb-2" />
          {formData.imzaSirküleri && <p>Yüklü Dosya: <a href={formData.imzaSirküleri} target="_blank" rel="noopener noreferrer">Dosyayı Görüntüle</a></p>}
          
          <label>Yetkili Kimlik Fotokopisi:</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'yetkiliKimlikFotokopisi')} className="border p-2 rounded w-full mb-2" />
          {formData.yetkiliKimlikFotokopisi && <p>Yüklü Dosya: <a href={formData.yetkiliKimlikFotokopisi} target="_blank" rel="noopener noreferrer">Dosyayı Görüntüle</a></p>}
          
          <label>Ticaret Sicil Gazetesi:</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'ticaretSicilGazetesi')} className="border p-2 rounded w-full mb-2" />
          {formData.ticaretSicilGazetesi && <p>Yüklü Dosya: <a href={formData.ticaretSicilGazetesi} target="_blank" rel="noopener noreferrer">Dosyayı Görüntüle</a></p>}
          
          <label>Kuyumcu Yetki Belgesi:</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'kuyumcuYetkiBelgesi')} className="border p-2 rounded w-full mb-2" />
          {formData.kuyumcuYetkiBelgesi && <p>Yüklü Dosya: <a href={formData.kuyumcuYetkiBelgesi} target="_blank" rel="noopener noreferrer">Dosyayı Görüntüle</a></p>}
          
          <label>Çerçeve Sözleşmesi:</label>
          <input type="file" onChange={(e) => handleFileChange(e, 'cerceveSozlesmesi')} className="border p-2 rounded w-full mb-2" />
          {formData.cerceveSozlesmesi && <p>Yüklü Dosya: <a href={formData.cerceveSozlesmesi} target="_blank" rel="noopener noreferrer">Dosyayı Görüntüle</a></p>}
        </div>

        {/* Cari açıklama veya not alanı */}
        <div className="mb-4">
          <label>Cari için açıklama veya not:</label>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            className="border p-2 rounded w-full mb-2"
            rows="4"
          />
        </div>

        <button type="submit" className="bg-green-500 text-white p-4 rounded">Veriyi Kaydet</button>
      </form>
    </div>
  );
};

export default UpdateData;
