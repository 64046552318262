import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // useNavigate eklendi
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/tr'; // Türkçe tarih formatı

const CompanyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate(); // Yönlendirme işlemi için useNavigate
  const [companyData, setCompanyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { companyId } = useParams();
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get(`https://demo.mashow.xyz/api/companies/company/${id}/data`);
        const company = response.data;

        let allData = [
          ...company.realPersons.map((person, index) => ({
            id: index + 1,
            ...person,
            companyType: "Şahıs Şirketi",
            companyName: person.name + ' ' + person.surname,
            signCircular: "VAR",
            customerInfoForm: "YOK",
            tradeRegistryGazette: "YOK",
            activityCertificate: "YOK",
            activityDate: person.taxRegistrationDate
              ? moment(person.taxRegistrationDate).format('DD.MM.YYYY')
              : 'Bilgi yok',
            taxNumber: person.TCKN,
            taxOffice: "YOK",
            goldsmithCert: "YOK",
            contact: person.address || 'Bilgi yok',
            note: 'Bilgi yok'
          })),
          ...company.legalPersons.map((person, index) => ({
            id: index + 1 + company.realPersons.length,
            ...person,
            companyType: "Limited Şirketi",
            companyName: person.title,
            signCircular: person.signatureCircular ? "VAR" : "YOK",
            customerInfoForm: "YOK",
            tradeRegistryGazette: person.tradeRegistryNumber ? "VAR" : "YOK",
            activityCertificate: "VAR",
            activityDate: "YOK",
            taxNumber: person.taxNumber,
            taxOffice: person.taxOffice || "Bilgi yok",
            goldsmithCert: person.kepAddress || "YOK",
            contact: person.phone || 'Bilgi yok',
            note: 'Bilgi yok'
          })),
          ...company.representatives.map((rep, index) => ({
            id: index + 1 + company.realPersons.length + company.legalPersons.length,
            ...rep,
            companyType: "Temsilci",
            companyName: rep.name + ' ' + rep.surname,
            signCircular: "VAR",
            customerInfoForm: "YOK",
            tradeRegistryGazette: "YOK",
            activityCertificate: "YOK",
            activityDate: rep.birthDate
              ? moment(rep.birthDate).format('DD.MM.YYYY')
              : 'Bilgi yok',
            taxNumber: rep.TCKN,
            taxOffice: "YOK",
            goldsmithCert: "YOK",
            contact: rep.address || 'Bilgi yok',
            note: 'Bilgi yok'
          }))
        ];

        setCompanyData(allData);
        setFilteredData(allData); // İlk başta tüm veriyi filtrelenmiş veriye ekliyoruz
      } catch (error) {
        console.error('Veriler çekilemedi:', error);
        Swal.fire({
          icon: 'error',
          title: 'Hata',
          text: 'Şirket verileri alınamadı!',
        });
      }
    };

    fetchCompanyData();
  }, [id]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filtered = companyData.filter(item =>
      item.companyName.toLowerCase().includes(value) || 
      item.companyType.toLowerCase().includes(value) || 
      item.taxNumber.toLowerCase().includes(value)
    );

    setFilteredData(filtered);
  };

  const handleViewContent = (rowId) => {
    navigate(`/dashboard/company/${id}/update/${rowId}`); // Şirket ID'sini dinamik olarak ekliyoruz
  };


  // Kaldır butonuna tıklandığında SweetAlert ile onay al
  const handleDelete = (rowId) => {
    Swal.fire({
      title: 'Bu veriyi kaldırmak istediğinize emin misiniz?',
      text: "Bu işlem geri alınamaz!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet, kaldır!',
      cancelButtonText: 'İptal'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://demo.mashow.xyz/api/companydata/${rowId}`); // Veriyi kaldır
          Swal.fire('Kaldırıldı!', 'Veri başarıyla kaldırıldı.', 'success');

          // Veriyi state'den kaldır
          const updatedData = companyData.filter(item => item.id !== rowId);
          setCompanyData(updatedData);
          setFilteredData(updatedData); // Filtrelenmiş veriyi de güncelle
        } catch (error) {
          Swal.fire('Hata!', 'Veri kaldırılamadı.', 'error');
        }
      }
    });
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
      width: '50px',
    },
    {
      name: 'FİRMA ÜNVANI',
      selector: (row) => row.companyType || 'Bilgi yok',
      sortable: true,
    },
    {
      name: 'ŞİRKET ADI',
      selector: (row) => row.companyName || 'Bilgi yok',
      sortable: true,
      wrap: true,
    },
    {
      name: 'İMZA SİRKÜLE',
      selector: (row) => row.signCircular || 'Bilgi yok',
      sortable: true,
    },
    {
      name: 'İŞLEMLER', // Butonlar için sütun
      cell: (row) => (
        <div className="flex space-x-2">
          <button
            onClick={() => handleViewContent(row._id)}
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
          >
            
            İçeriği Gör
          </button>
          <button
            onClick={() => handleDelete(row.id)}
            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
          >
            
            Kaldır
          </button>
        </div>
      ),
      width: '200px', // Sütun genişliği
    },
  ];

  const expandableRowsComponent = ({ data }) => (
    <div className='p-4'>
      <table>
        <tbody>
          <tr>
            <td><strong>MÜŞTERİ BİLGİ FORMU:</strong></td>
            <td>{data.customerInfoForm || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>TİCARET SİCİL GAZETESİ:</strong></td>
            <td>{data.tradeRegistryGazette || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>FAALİYET BELGESİ:</strong></td>
            <td>{data.activityCertificate || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>FAALİYET BELGE TARİHİ:</strong></td>
            <td>{data.activityDate || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>VERGİ NUMARASI:</strong></td>
            <td>{data.taxNumber || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>VERGİ DAİRESİ:</strong></td>
            <td>{data.taxOffice || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>KUYUMCU YETKİ BELGESİ:</strong></td>
            <td>{data.goldsmithCert || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>İLETİŞİM:</strong></td>
            <td>{data.contact || 'Bilgi yok'}</td>
          </tr>
          <tr>
            <td><strong>NOT:</strong></td>
            <td>{data.note || 'Bilgi yok'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Şirket Verileri</h2>

      <input
        type="text"
        placeholder="Ara..."
        className="mb-4 p-2 border border-gray-300 rounded w-full"
        value={searchText}
        onChange={handleSearch}
      />

      <DataTable
        columns={columns}
        data={filteredData}
        expandableRows
        expandableRowsComponent={expandableRowsComponent}
        pagination
        highlightOnHover
        striped
        noHeader
        responsive={true}
        noDataComponent="Gösterilecek veri yok"
        paginationComponentOptions={{
          rowsPerPageText: 'Sayfa başına satır:',
          rangeSeparatorText: 'ile',
          selectAllRowsItem: true,
          selectAllRowsItemText: 'Tümünü Seç',
        }}
      />
    </div>
  );
};

export default CompanyDetails;
