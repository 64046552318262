import React, { useState } from 'react';
import axios from 'axios';

const CompanyAdd = () => {
  const [name, setName] = useState('');
  const [info, setInfo] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://demo.mashow.xyz/api/companies', { name, info });
      alert('Şirket başarıyla oluşturuldu!');
      setName('');
      setInfo('');
    } catch (error) {
      console.error('Şirket oluşturulamadı:', error);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Şirket Oluştur</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2">Şirket Adı</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border p-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Şirket Bilgileri</label>
          <textarea
            value={info}
            onChange={(e) => setInfo(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2">Oluştur</button>
      </form>
    </div>
  );
};

export default CompanyAdd;
